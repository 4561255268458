import React from 'react'
import PropTypes from 'prop-types'
import Month from './Month'

const EventsListing = ({ events }) =>
  events.map(monthlyEventsListing => (
    <Month
      monthlyEventsListing={monthlyEventsListing}
      key={monthlyEventsListing.date}
    />
  ))

EventsListing.propTypes = {
  query: PropTypes.string,
}

export default EventsListing
