import React from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'
import { Heading } from 'grommet'
import Events from '../Calendar/Events'
import PropTypes from 'prop-types'
import {
  Table, TableBody, TableCell, TableHeader, TableFooter, TableRow,
  Text,
} from 'grommet'
import moment from 'moment'


const parseInputForCurrency = (input) => {
  if (!input) return 'Free'

  if (input.trim()[0] === '$') {
    if (isNaN(input.trim().substring(1))) {
      return input
    } else {
      return amountFormatter.format(input.trim().substring(1))
    }
  } else {
    if (input.trim().length === 0) {
      return 'Free'
    } else if (isNaN(input.trim())) {
      return input
    } else {
      return amountFormatter.format(input)
    }
  }
}

const amountFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

const Month = ({ monthlyEventsListing, showModal }) => {

  const COLUMNS = [
    {
      property: 'start',
      label: 'Start Day and Time',
      format: event => event.frontmatter.start ? moment(event.frontmatter.start).format("dddd, MMMM D, hh:mm A") : '',
      size: 'small',
      link: false,
    },
    {
      property: 'end',
      label: 'End Day and Time',
      format: event => event.frontmatter.end ? moment(event.frontmatter.end).format("dddd, MMMM D, hh:mm A") : '',
      size: 'small',
      link: false,
    },
    {
      property: 'title',
      label: 'Event',
      format: event => event.frontmatter.title,
      size: 'small',
      link: false,
    },
    {
      property: 'entryFee',
      label: 'Entry Fee',
      format: event => parseInputForCurrency(event.frontmatter.entryFee),
      size: 'small',
      link: false,
    },
    {
      property: 'excerpt',
      label: 'Excerpt',
      format: event => event.excerpt,
      size: 'large',
      link: true,
    },
  ];

  const [currentMonthNumber, currentYear] = monthlyEventsListing.date.split('-')

  const currentMonth = new Date(
    currentYear,
    parseInt(currentMonthNumber, 10) - 1,
    1,
  )

  return (
    <React.Fragment>
      <Heading a11yTitle={`Month of ${format(currentMonth, 'MMMM YYYY')}`}>
        <b>{`${format(currentMonth, 'MMMM')} `}</b>
        {format(currentMonth, 'YYYY')}
      </Heading>
      <Table caption='Events'>
        <TableHeader>
          <TableRow>
            {COLUMNS.map(c => (
              <TableCell key={c.property} scope='col' border='bottom' align={c.align} size={c.size}>
                <Text>{c.label}</Text>
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
	  {monthlyEventsListing.events.map(event => (
            <TableRow key={event.id}>
              {COLUMNS.map(c => (
                <TableCell key={c.property} scope={c.dataScope} verticalAlign='top' align={c.align} size={c.size}>
                  <Text>
                    {c.format ? c.format(event) : event[c.property]}
                  </Text>
                  {c.link ? <Link className="button is-small" to={event.fields.slug}>Keep Reading →</Link> : ``}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            {COLUMNS.map(c => (
              <TableCell key={c.property} border='top' align={c.align} size={c.size}>
                <Text>{c.footer}</Text>
              </TableCell>
            ))}
          </TableRow>
        </TableFooter>
      </Table>
    </React.Fragment>
  )
}

Month.propTypes = {
  monthlyEventsListing: PropTypes.shape({
    events: Events.propTypes.events,
    when: PropTypes.shape({
      month: PropTypes.string.isRequired,
      year: PropTypes.string.isRequired,
    }),
  }),
  showModal: PropTypes.func.isRequired,
}

Month.defaultProps = {
  monthlyEventsListing: {
    events: [],
    when: {
      month: '',
      year: '',
    },
  },
}

export default Month
