import React, { PureComponent } from 'react'
import { Box, Heading } from 'grommet'
import { StaticQuery, graphql, Link } from 'gatsby'
import EventsListing from '../../components/EventsListing'
import CalendarListHeader from '../../components/CalendarListHeader'
import groupEventsByMonth from '../../utils/groupEventsByMonth'
import CalendarConfigContext from '../../components/CalendarConfigContext'
import Layout from '../../components/Layout'

class EventsIndexPage extends PureComponent {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <Heading margin='small'>Events Around the Big Thicket</Heading>
            <CalendarListHeader />
            <Link size="small" to="/calendar">
              View Events on Calendar
            </Link>

            <CalendarConfigContext.Consumer>
              {({ monthsIntoTheFuture }) => (
                <StaticQuery
                  query={graphql`
                    query EventsIndexQuery {
                      allMarkdownRemark(
                        sort: { order: ASC, fields: [frontmatter___start] },
                        filter: { frontmatter: { templateKey: { eq: "event-post" } }}
                      ) {
                        edges {
                          node {
                            excerpt(pruneLength: 400)
                            id
                            fields {
                              slug
                            }
                            frontmatter {
                              title
                              templateKey
                              start
                              end
                              entryFee
                            }
                          }
                        }
                      }
                    }
                  `}
                  render={data => (

                    <React.Fragment>
                      <Box id="calendars" animation="fadeIn" margin="medium">
                        <EventsListing
                          events={groupEventsByMonth(data, monthsIntoTheFuture)}
                        />
                      </Box>
                    </React.Fragment>
                  )}
                />
              )}
            </CalendarConfigContext.Consumer>
          </div>
        </section>
      </Layout>
    )
  }
}

export default EventsIndexPage
